<template>
    <div class="w-100 colon-broom-container" ref="container">
        <div class="colon-broom-progress-bar w-100">
        <div class="active-bar" :style="{ width: (PageOrder/totalPagesLength)*100 + '%' }"></div>
        </div>
        <div class="colon-broom-info justify-content-between">
            <div class="colon-broom-image-dev"><LazyImage class="colon-broom-image" src="https://images.quizell.com/website/colonbroomlogo.svg"></LazyImage></div>
            <div class="colon-broom-page-no">{{ PageOrder }} of {{ totalPagesLength }}</div>
          </div>
<LazyImage class="d-none d-md-block"  src="https://images.quizell.com/colon-broom/steps-dots.svg" style="   position: fixed;
    width: 63px;
    height: 102px;
    left: 0px;
    top: 36%;" ></LazyImage>
        <div class="">
         
<LazyImage class="step-black" src="https://images.quizell.com/colon-broom/step-black.svg" style="position: fixed; bottom: 0px; right: 0px;" ></LazyImage>
</div>

  <div class="row kozima-container row position-relative m-0" style="padding-bottom: 48px;">
<div class="col-12">
    <div class=""><h4 class="question-heading">{{ getHeading[0].content }}
    </h4></div>

    <div class="colon-broom-options justify-content-center ">
        <div class="colon-broom-option  justify-content-md-center flex-md-column" :class="[!option.image?'multi':'', isNextPageLoading==index||getSelectedOption(option)?'colon-broom-selected-option':'',isNextPageLoading!=null?'cursor-none':'']"   @click="updateSelectedAnswer(option, index)" v-for="(option,index) in getOptions"
        :key="option.id">
           
                <LazyImage v-if="option.image" class="option-image" :src="option.image"></LazyImage>
                <div class="colon-broom-text">{{ option.value }}</div>
    
        </div>
       
    </div>
</div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center px-2 mb-5">

<div class=""  style="cursor:pointer; padding-top: 32px;">
  <div class="d-flex gap align-items-center" @click="backPage">
    <div class="colon-broom-svg">
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_211_2723)">
<path d="M5.50618 7.21666C7.75027 7.21666 9.99437 7.21666 12.2385 7.21666C13.1534 7.21666 14.0683 7.29029 14.9796 7.38065C15.646 7.44758 16.5177 7.44089 16.4997 8.32105C16.4817 9.20122 15.5884 9.16106 14.9364 9.22465C12.9481 9.4154 10.9525 9.40202 8.95697 9.40536H6.47514C6.50395 9.90736 6.90378 10.0011 7.17034 10.1651C8.19705 10.8167 9.27329 11.3983 10.3906 11.9053C11.0354 12.1864 11.5144 12.6449 11.1939 13.351C10.8733 14.0572 10.1493 14.1208 9.44685 13.8564C8.80905 13.6195 8.1934 13.3341 7.60619 13.003C5.90241 12.0425 4.26346 10.9883 2.39038 10.309C2.15308 10.2096 1.92838 10.0862 1.72039 9.94083C0.113862 8.9201 0.0922496 8.07675 1.66276 6.99913C3.87083 5.48311 6.02127 3.88676 8.46709 2.69536C8.93519 2.46933 9.41618 2.26711 9.90792 2.08962C10.3366 1.93233 10.776 1.95575 11.0246 2.37408C11.2731 2.79241 11.0534 3.15385 10.6644 3.39815C9.75303 3.99051 8.82009 4.55274 7.90516 5.1384C7.01361 5.64373 6.18908 6.24467 5.44854 6.92885L5.50618 7.21666Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_211_2723">
<rect width="16" height="16" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>

    </div>
    <div class="back-button-text">
      Go back
    </div>
  </div>
</div>
</div>
</div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getFormatedUTCTimeStamp } from "../../../../composibles/getUTCTimeStamp";
export default {
    props: [
    "isTest",
    "isDesktopView",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
    "apiToken",
  ],
  data(){
   return{ pageDesign:{},
   isNextPageLoading: null,}
  },
  methods: {
    ...mapActions([
      "goToNextPreviewPage",
      "goToBackPreviewPage",
      "updateSelectedOptionsObject",
      "createEndQuizPage",
      "kozimaCheckTimer",
    ]),
    ...mapMutations([
      "jumpToPreviewPage",
      "PreviewPagesStack",
      "backPageAnimation",
      "setIsLoadingQuestion",
    ]),
    backPage() {
      this.backPageAnimation();
      this.goToBackPreviewPage();
    },
    getSelectedOption(option){
      if(this.getSelectedOptions?.questionAnswer)
    {
      // console.log(this.getSelectedOptions?.questionAnswer[0].selectedOption.id,'selected')
      return this.getSelectedOptions?.questionAnswer.findIndex(op=>option.id==op.selectedOption.id)>-1
    }
    return false
    },
    getSinglePageValues() {
      let getStartedDesignValues = {};
      if (this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },
    async updateSelectedAnswer(value, selectedOptionIndex) {
      if (!value) return;
    
      if (this.isLoadingQuestion) return;
      const contentIndex = this.findOptionsIndex;
      await this.processSelectedOption(
        value,
        contentIndex,
        selectedOptionIndex
      );
    },
    async IncrementQuestionAnalyticsCount(
      selectedOptionObj,
      mode,
      selectedOptionIndex
    ) {
      if (this.isTest) {
        if (mode == "jumpLogic") return true;

        this.goToNextPreviewPage(this.getSelectedPage.value);
        return false;
      }

      let { quiz_analytic_id } = this.getSavedLeadID;

      if (quiz_analytic_id) {
        try {
          this.isNextPageLoading = selectedOptionIndex;
          this.setIsLoadingQuestion(true);
          let quizAnalyticsPayload = {
            api_token: this.apiToken || this.$route.params.id,
            question_id: this.CurrentPage.id,
            quiz_analytic_id: quiz_analytic_id,
            option_ids:
              selectedOptionObj.selectedOption &&
              "id" in selectedOptionObj.selectedOption
                ? [selectedOptionObj.selectedOption.id]
                : [],
          };

          const response = await axios.post(
            `/api/question/analytic/add`,
            quizAnalyticsPayload
          );

          if (response.status == 200) {
            if (mode == "jumpLogic") {
              return true;
            }

            this.goToNextPreviewPage(this.getSelectedPage.value);
            return false;
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.setIsLoadingQuestion(false);
          this.isNextPageLoading = null;
        }
      } else {
        try {
          this.isNextPageLoading = selectedOptionIndex;
          let data = {
            api_token: this.apiToken || this.$route.params.id,
            quizId: this.getPreviewSettings.quizId,
            value_type: "Started",
            started_at: getFormatedUTCTimeStamp(),
          };

          const response = await axios.post(`/api/updateQuizAnalytic`, data);
          if (response.status == 200) {
            let { quiz_analytic_id } = response.data.data;
            if (quiz_analytic_id) {
              await this.$store.commit(
                "assignQuizAnaylticsId",
                quiz_analytic_id
              );
              this.IncrementQuestionAnalyticsCount(
                selectedOptionObj,
                mode,
                selectedOptionIndex
              );
            }
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.isNextPageLoading = null;
        }

        // Recursive call
      }
    },

    async processSelectedOption(value, contentIndex, selectedOptionIndex) {
      try {
        const selectedOptionObj = this.createSelectedOptionObj(value);
        this.pageDesign.blocksArray[contentIndex].selectedOption = value;
        await this.updateSelectedOptionsObject(selectedOptionObj);

        this.IncrementQuestionAnalyticsCount(
          selectedOptionObj,
          "normal",
          selectedOptionIndex
        );
      } catch (error) {
        if (error) throw error;
      }
    },
    createSelectedOptionObj(value) {
      const obj = new Object();
      obj.questionId = this.getSelectedPage.id;
      obj.isMasterQuestion = this.getSelectedPage.isMasterQuestion;
      obj.questionType = this.getSelectedPage.value;
      obj.selectedOption = value;
      return obj;
    },
  },
  computed: {
    ...mapGetters([
      "getIsLoadingQuestion",
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getTotalPreviewPages",
      "getPreviewSettings",
      "getPreviewProgressBar",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
      "getFurnitureClientCounterData",
      "getTrackIkeaClientCounter",
      "getEnvConst",
     "getSelectedOptions"
    ]),
    totalPagesLength() {
      const allowedValues = ["singleAnswer", "multipleAnswer","leadPage"];

      return this.getTotalPreviewPages.reduce(
        (count, x) => (allowedValues.includes(x.value) ? count + 1 : count),
        0
      )-1;
      //-1 because only one lead page have progressbar
    },
    filteredQuestions() {
      const allowedValues = ["singleAnswer", "multipleAnswer","leadPage"];
      return this.getTotalPreviewPages.filter((page) =>
        allowedValues.includes(page.value)
      );
    },
    PageOrder() {
      const index = this.filteredQuestions.findIndex(
        (page) => page.order === this.CurrentPage.order
      );
      if (index !== -1) {
        return index+1;
      }
      return null;
    },
    calcWidth() {
      const arr = this.getTotalPreviewPages;
      let totalPagesLength = this.getTotalPreviewPages.length
      let progressWidth=null;
       
      if (arr.length) {
        for (let i = 0; i < arr.length; i++) {
          
          if (
            this.getSelectedPage.value == arr[i].value &&
            this.getSelectedPage.order == arr[i].order
          ) {
            progressWidth = ((i + 1) / totalPagesLength) * 100;
          
          }
        }
      }
      return progressWidth;
    },
    getHeading() {
      return this.getSelectedPage.pageDesign.blocksArray.filter(
        (block) => block.type == "text"
      );
    },
    getOptions() {
      return this.pageDesign.blocksArray[this.findOptionsIndex].options;
    },
    findOptionsIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "option"
      );
      return index;
    },
    isLoadingQuestion() {
      return this.getIsLoadingQuestion;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
  },
mounted(){
    // document.body.appendChild(this.$refs.container);
   
},
destroyed(){
    // if (this.$refs.container) {
    //     document.body.removeChild(this.$refs.container);
    //   }
},
created() {
    this.pageDesign = this.getSinglePageValues();
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@font-face {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 500;
    src: local('Recoleta Regular DEMO'), url('https://images.quizell.com/fonts/recoleta.woff') format('woff');
    }

.__className_acbbe0 {
    font-family: Recoleta, Recoleta1;
}

@media (max-width: 639px) {
    .step-black {
        width: 43px;
        height: 64px;
    }
    .colon-broom-text{
      text-align: left;
    }
    .colon-broom-next-button{
        width: 100%!important;
    }
}
@media (min-width: 768px) {
    .question-heading.question-heading {
        margin: 0px auto 48px;
    }
    .question-heading {
        font-family: Recoleta, Recoleta1;
        font-size: 2rem;
        font-weight: 500;
        line-height: 2.5rem;
    }
}
.colon-broom-container{
    font-family: Recoleta  "Poppins";
}

.question-heading.question-heading {
    max-width: 900px;
    text-align: center;
    margin: 0px auto 32px;
   
}


.question-heading {
    display: block;
    z-index: 2;
    font-family: Recoleta, Recoleta1;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    text-decoration: none;
}

.colon-broom-progress-bar{
height: 8px;
background-color: #E8F6F2;
}
.colon-broom-progress-bar .active-bar{
    position: relative;
    height: 8px;
    background-color: rgb(34, 191, 142);
    transition: width 0.2s;
}
.colon-broom-progress-bar .active-bar::after {
    position: absolute;
    width: 18px;
    height: 19px;
    content: "";
    background-image: url(https://images.quizell.com/colon-broom/progress-barDrum.svg);
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: contain;
    right: -18px;
}

.colon-broom-info {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 20px;
}

@media (min-width: 768px) {
    .colon-broom-info {
        padding: 34px 24px 56px;
    }
}

.colon-broom-info {
    max-width: 1200px;
    padding: 24px 24px 32px;
    margin: 0px auto;
  
}
@media (min-width: 640px) {
    .colon-broom-image {
        height: 19px!important;
    }
}

.colon-broom-image {
    height: 15px;
}

.colon-broom-image-dev {
    display: block;
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
    left: 0px;
    box-sizing: border-box;
    top: 40px;
}

.colon-broom-options {
    display: flex;
    -webkit-box-pack: center;
  
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 0px 16px;
    gap: 24px;
    max-width: 1240px;
    margin: auto;
}

.colon-broom-option.multi {
    width: 288px;
    height: 75px;
    padding: 0px;
    background-color: rgb(247, 249, 248);
}
@media (max-width: 639px) {
    .colon-broom-option {
        width: 100%!important;
        height: 75px!important;

    background-color: rgb(247, 249, 248)!important;
    padding: 20px!important;
    }
}

.colon-broom-option {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    width: 208px;
    height: 238px;
    text-align: center;
    background: rgb(247, 249, 248);
    border: 2px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    gap:20px;
}
/* .colon-broom-option div {
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    padding: 0px 24px;
    transform: translateY(-50%);
} */
.colon-broom-text   {
    display: block;
    z-index: 2;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    text-decoration: none;
}


@media (max-width: 639px) {
    .option-image {
        width: 40px!important;
        height: auto!important;
    }
}
.option-image {
    width: auto;
    height: 90px;
}
.colon-broom-selected-option{
  border-radius: 4px;
border: 2px solid var(--Green-Base, #22BF8E);
background: var(--Green-Lighter, #E8F6F2);
}
.cursor-none{
  pointer-events: none;
}
.back-button-text{
  color: var(--Grey-Black, #000);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.colon-broom-page-no{
  color: var(--Grey-Black, #000);
font-feature-settings: 'liga' off, 'clig' off;

/* Desktop/Subtitle 2 */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
white-space: nowrap;

}
</style>